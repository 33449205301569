import {Helmet} from "react-helmet";

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>¯\_(ツ)_/¯ - joaquin.muñoz.dev</title>
      </Helmet>
      <main className="w-full h-screen flex flex-col items-center justify-center content-center">
        <h1 className="font-mono text-3xl font-bold">¯\_(ツ)_/¯</h1>
      </main>
    </>
  )
}
